/**
 *
 * @returns {*}
 */
export function getList(companyId, cityId) {
    return axios({
        url: `/api/company-branches`,
        method: 'get',
        params: {
            companyId: companyId,
            cityId: cityId
        }
    });
}
