<template>
    <div>
       <div v-if="policyInfo" :class="['policy', {'hidden':show != true}]">
           <button class="hide" @click="show = false">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 1L1 17" stroke="black" stroke-width="2"/>
                    <path d="M1 1L17 17" stroke="black" stroke-width="2"/>
                </svg>
           </button>
            <a href="#" v-if="canEdit" class="change link collapsed" data-toggle="collapse" data-target="#searchcollapse" aria-expanded="false" aria-controls="#searchcollapse" @click="scrollTo('#searchcollapse')">Змінити</a>
            <div class="title d-lg-block">Ваш поліс</div>
            <hr class="d-none d-lg-block">
            <!--<div class="specific" v-if="policyInfo.car.brand">
                <b>Производитель</b>
                <p>{{policyInfo.car.brand}}</p>
            </div>
            <div class="specific d-none d-lg-block" v-if="policyInfo.car.model">
                <b>Модель</b>
                <p>{{policyInfo.car.model}}</p>
            </div>-->
            <div class="specific d-lg-block">
                <b>Місто реєстрації</b>
                <p>{{policyInfo.cityName}}</p>
            </div>
            <div class="specific d-lg-block">
                <b>Франшиза</b>
                <p>{{showFranchise(policyInfo.franchise)}}</p>
            </div>
            <div class="specific d-lg-block">
               <b>Категорія автомобіля</b>
               <p>{{policyInfo.autoCategoryType}}</p>
            </div>
            <div class="specific d-lg-block">
               <b>Тип користувача</b>
               <p v-text="userType"></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['canEdit', 'searchParams'],
        data: () => ({
            privilegeList: [],
            userTypeList: [],
            show: true
        }),

        created() {
            this.privilegeList = this.$store.getters.CUSTOMER_PRIVILEGE;
            this.userTypeList = this.$store.getters.CUSTOMER_TYPES;
        },

        computed: {
            policyInfo() {
                return this.$store.state.osagoStore.requestData ?? this.searchParams;
            },
            userType(){
                let userType;
                if(this.policyInfo.customerPrivilege == 2){
                    this.privilegeList.forEach((item) => {
                        if(item.id === this.policyInfo.customerPrivilege){
                            userType = item.value;
                        }
                    });
                }else{
                    this.userTypeList.forEach((item) => {
                        if(item.id === this.policyInfo.customerType){
                            userType = item.value;
                        }
                    });
                }

                return userType;
            },
        },

        methods: {
            showFranchise(franchise) {
                if (franchise == 'all') {
                    return 'Всі франшизи'
                } else {
                    return `${franchise} грн`
                }
            },
            scrollTo(item){
                let el = item;
                $('html,body').animate({
                    scrollTop: $(el).offset().top + 150
                }, 1000);
            },
        }
    }
</script>
