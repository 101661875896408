<template>
    <div class="links">
        <client-propositions-block
            v-if="isLinkClientProposition() && linkData"
            :data-link="linkData"
        ></client-propositions-block>
        <div v-else-if="isLinkPenalty()" class="penalty-block"></div>
    </div>
</template>


<script>
    import Vue from "vue";
    import ClientPropositionsBlock from '@/views/links/clientPropositionsBlock.vue';
    import Pagination from '@/components/Pagination';
    import { showLink, saveLink } from "@/api/link";

    const FileDownload = require('js-file-download');

    export default {
        components: {
            Pagination,
            ClientPropositionsBlock,
        },
        data: function () {
            return {
                mobileWith: 992,
                linkData: null,
                linkType: 1,
                condition: {
                    isLoadTable: true,
                    isMobile: false,
                },
                listQuery: {
                    page: 1,
                    limit: 10,
                }
            }
        },
        created: function() {
            window.addEventListener("resize", this.checkWindowResize);
            this.checkIsMobile();
        },
        mounted: function() { // The hook. Here, Vue has already worked its magic.
            this.initLinkData();
        },
        computed:{

        },
        methods: {
            initLinkData: async function() {
                let resp = await showLink(this.$route.params.id).then((response) => {
                    return response.data.data;
                });
                this.linkType = resp?.typeId;
                this.linkData = resp;
            },
            isLinkClientProposition: function () {
                return this.linkType == 1;
            },
            isLinkPenalty: function () {
                return this.linkType == 2;
            },
            checkIsMobile: function() {
                return this.condition.isMobile = window.innerWidth < this.mobileWith;
            },
            checkWindowResize: function() {
                this.checkIsMobile()
            },
        },
        destroyed() {
            window.removeEventListener("resize", this.checkWindowResize);
        },
    }
</script>
